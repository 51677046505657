import React from "react"
import PropTypes from "prop-types"

import { Text } from "@nerdwallet/react-typography"
import Divider from "@nerdwallet/react-divider"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"

import styles from "./NextStepsWrapper.module.less"

const NextStepsWrapper = ({ category, nextSteps }) => {
  return (
    <div className={styles.container}>
      <div className={styles.marginBottom}>
        <h2 className={styles.sectionTitle}>
          Dive even deeper in {category.name}
        </h2>
        <StructuredContentRenderer>{nextSteps}</StructuredContentRenderer>
      </div>
      <Divider />
      <div className={styles.marginVertical}>
        <a href={category.link}>
          <Text inline>Explore</Text>{" "}
          <Text inline bold uppercase>
            {category.name}
          </Text>
        </a>
      </div>
    </div>
  )
}

NextStepsWrapper.propTypes = {
  category: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  nextSteps: PropTypes.shape({}),
}

NextStepsWrapper.defaultProps = {
  nextSteps: null,
}

export default NextStepsWrapper
