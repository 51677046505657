import React from "react"
import PropTypes from "prop-types"

import StickyRail from "@src/components/StickyRail"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import SideTableOfContents from "@src/components/SideTableOfContents"

import styles from "./SideRailContent.module.less"

const ALLOWED_COMPONENT_TYPES = ["WPHouseAd", "WPPostList"]

const filterStructuredContent = structuredContent => {
  if (!structuredContent) return null

  const toArray = data => (Array.isArray(data) ? data : [data])

  const filterFunction = structuredContent => {
    if (ALLOWED_COMPONENT_TYPES.includes(structuredContent?.type)) {
      return true
    }

    const [secondLevelData] = toArray(structuredContent?.props?.children)
    return ALLOWED_COMPONENT_TYPES.includes(secondLevelData?.type)
  }

  const result = toArray(structuredContent).filter(filterFunction)
  if (result.length === 0) return null

  return result
}

const SideRailContent = ({ structuredContent, smbTableOfContents }) => {
  const data = filterStructuredContent(structuredContent)
  if (!data && !smbTableOfContents) return null
  const opacityAnimation = !smbTableOfContents

  return (
    <StickyRail opacityAnimation={opacityAnimation} heightOffset={400}>
      <div className={styles.stick}>
        {smbTableOfContents && (
          <SideTableOfContents desktopOnly {...smbTableOfContents} />
        )}
        {data && (
          <>
            <div className={styles.title}>On a similar note...</div>
            <StructuredContentRenderer>{data}</StructuredContentRenderer>
          </>
        )}
      </div>
    </StickyRail>
  )
}

SideRailContent.propTypes = {
  structuredContent: StructuredContentRenderer.propTypes.children,
  smbTableOfContents: PropTypes.shape({}),
}

export default SideRailContent
