import React from "react"
import PropTypes from "prop-types"

import { Title } from "@nerdwallet/react-typography"

import StructuredContentRenderer from "@src/components/StructuredContentRenderer"

import styles from "./Methodology.module.less"

const Methodology = ({ methodology }) => {
  return (
    <>
      <Title component="h2" className={styles.title} size="small">
        Methodology
      </Title>
      <div className={styles.content}>
        <StructuredContentRenderer>{methodology}</StructuredContentRenderer>
      </div>
    </>
  )
}

Methodology.propTypes = {
  methodology: PropTypes.shape({}),
}

export default Methodology
