import React from "react"
import PropTypes from "prop-types"

import classNames from "classnames"

import Lazy from "react-lazyload"
import { Body, Title } from "@nerdwallet/react-typography"
import styles from "./AuthorsSection.module.less"

const AuthorBox = ({ authorBox }) => {
  return (
    <div className={styles.container}>
      <a href={authorBox.link} aria-label={authorBox.name}>
        <Lazy className={styles.imageWrapper}>
          <figure>
            <picture>
              <img
                className={styles.imageCircle}
                alt={authorBox.name}
                src={authorBox.avatarUrls[0].url}
                height="70px"
                width="70px"
              />
            </picture>
          </figure>
        </Lazy>
      </a>
      <div className={styles.marginLeft}>
        <Title size="small">{authorBox.name}</Title>
        <Body>
          <p
            className={styles.textDefault}
            dangerouslySetInnerHTML={{ __html: authorBox.marketplaceBio }}
          />
          <div className={styles.marginTop}>
            {authorBox.twitter && (
              <div
                className={classNames(
                  styles.authorIconContainer,
                  styles.removeBorder
                )}
                style={{
                  marginRight: "1rem",
                }}
              >
                <a
                  href={`https://twitter.com/${authorBox.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`${authorBox.name} twitter profile`}
                  style={{ marginRight: "2rem" }}
                >
                  <span>
                    <svg
                      className={styles.authorIconSize}
                      viewBox="0 0 2048 2048"
                      aria-hidden="true"
                      fill="currentcolor"
                      focusable="false"
                    >
                      <path d="M2048 367q-84 127-209 218v55q0 140-35.5 281.5t-102 275T1531 1446t-233 202.5-297.5 136.5-355.5 50q-379 0-645-171 46 7 100 7 140 0 274-54t247-145q-135-1-243.5-83.5T230 1180q42 8 78 8 58 0 112-15-145-29-241-144.5T83 761v-5q90 49 190 52-87-58-137.5-150T85 460q0-103 57-204 158 192 382 304.5T1007 687q-9-39-9-97 0-174 122.5-296.5T1417 171q179 0 309 132 143-29 265-102-48 152-185 233 125-14 242-67z"></path>
                    </svg>
                  </span>
                  <span className={styles.hideIcon}>Twitter</span>
                </a>
              </div>
            )}
            {authorBox.contactEmail && (
              <div className={styles.authorIconContainer}>
                <a
                  href={`mailto:${authorBox.contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`${authorBox.name} e-mail`}
                  style={{ marginRight: "2rem" }}
                >
                  <span>
                    <svg
                      className={styles.authorIconSize}
                      viewBox="0 0 2048 2048"
                      fill="currentcolor"
                      focusable="false"
                    >
                      <path d="M2048 384l-1024 768L0 384q0-53 37.5-90.5T128 256h1792q53 0 90.5 37.5T2048 384zM0 614l1024 768 1024-768v1050q0 53-37.5 90.5T1920 1792H128q-53 0-90.5-37.5T0 1664V614z"></path>
                    </svg>
                  </span>
                  <span className={styles.hideIcon}>Email</span>
                </a>
              </div>
            )}
            {authorBox.linkedin && (
              <div
                style={{
                  display: "inline-block",
                  paddingLeft: "1rem",
                  borderLeft: "1px solid #d8d9da",
                }}
              >
                <a
                  className={styles.hideIcon}
                  href={authorBox.linkedin}
                  target="_blank"
                  aria-label={`${authorBox.name} LinkedIn profile`}
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            )}
          </div>
        </Body>
      </div>
    </div>
  )
}

AuthorBox.propTypes = {
  authorBox: PropTypes.shape({
    link: PropTypes.string.isRequired,
    avatarUrls: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    marketplaceBio: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    twitter: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
  }).isRequired,
}

const AuthorsSection = ({ authorBoxes }) => {
  return (
    <aside className={styles.aboutAuthorContainer}>
      <Title
        size="medium"
        className={styles.aboutAuthor}
        style={{ borderBottom: "1px solid" }}
      >
        About the author
      </Title>
      {authorBoxes.map(authorBox => (
        <AuthorBox key={authorBox.id} authorBox={authorBox} />
      ))}
    </aside>
  )
}

AuthorsSection.propTypes = {
  authorBoxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default AuthorsSection
