import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import classNames from "classnames"

import { ContentImpression } from "@src/lib/impression"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import AuthorsSection from "@src/components/AuthorsSection"

import Box from "@src/components/Box"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import NextStepsWrapper from "@src/components/NextStepsWrapper"

import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"
import ArticleSources from "@src/components/ArticleSources"
import InfoGatheringCTA from "@src/components/InfoGatheringCTA"
import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"
import { ActivePageSectionProvider } from "@src/hooks/useActivePageSection"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import { CitationContextProvider } from "@src/contexts/CitationContext"
import { getCanonicalLink } from "@src/lib/helpers"

import styles from "./article.module.less"
import Methodology from "../components/article/Methodology"

import SideRailContent from "@src/components/SideRailContent"
const Article = ({ data, location }) => {
  const { smbArticle: article } = data
  const getCustomOrRegularContent = (
    regularFieldName,
    customFieldName,
    useCustomFieldName
  ) => {
    const {
      [regularFieldName]: regularContent,
      [customFieldName]: customContent,
      [useCustomFieldName]: useCustomContent,
    } = article

    return useCustomContent ? customContent : regularContent
  }

  const nextSteps = getCustomOrRegularContent(
    "nextSteps",
    "customNextSteps",
    "useCustomNextSteps"
  )
  const relatedLinks = getCustomOrRegularContent(
    "relatedLinks",
    "customRelatedLinks",
    "useCustomRelatedLinks"
  )

  useAnalyticsPageTrack(article?.contentId)

  const smbTableOfContents = Array.isArray(article.content)
    ? article.content.find(c => c.type === "smbTableOfContents")?.props
    : null

  const header = (
    <Header
      attributionTopic={article.attributionTopic}
      hideBanner={!!article.heroBannerDocument}
    />
  )

  const headerContent = (
    <HeaderContent
      title={article.title}
      date={article.displayDate}
      authors={article.authors}
      synopsis={
        article.synopsis && (
          <StructuredContentRenderer>
            {article.synopsis}
          </StructuredContentRenderer>
        )
      }
      breadcrumb={article.breadcrumb}
      featuredImage={article.featuredMedia}
      heroBannerDocument={
        article.heroBannerDocument && (
          <StructuredContentRenderer>
            {article.heroBannerDocument}
          </StructuredContentRenderer>
        )
      }
      assigningEditor={article.assigningEditor}
      hideAuthorByline={!article.showAuthorByline}
      hideEditorialStandards={!article.showEditorialDisclaimer}
      hideFeaturedImage={article.hideFeaturedImage}
    />
  )
  const footer = (
    <Footer
      attributionTopic={article.attributionTopic}
      hideBanner={!!article.heroBannerDocument}
    />
  )

  const page = (
    <Page
      title={article.title}
      link={getCanonicalLink(article)}
      featuredImage={article.featuredMedia}
      seo={article.seo}
      jsonld={article.jsonld}
      header={header}
      headerContent={headerContent}
      footer={footer}
    >
      <main>
        <Columns gap="1">
          <Column width={{ desktop: 8, mobile: 12 }}>
            <div className={classNames(styles.section, styles.content)}>
              <CitationContextProvider>
                {article.attributionTopic === "Business Credit Cards" && (
                  <InfoGatheringCTA requireFields />
                )}
                <StructuredContentRenderer>
                  {article.content}
                </StructuredContentRenderer>
                <ArticleSources />
              </CitationContextProvider>
            </div>

            <Box className={styles.section}>
              <AuthorsSection authorBoxes={article.authorBox} />
            </Box>

            {article.methodology?.content && (
              <div className={styles.section}>
                <Methodology methodology={article.methodology.content} />
              </div>
            )}
          </Column>

          <Column width={{ desktop: 4, mobile: 12 }}>
            <SideRailContent
              structuredContent={relatedLinks}
              smbTableOfContents={smbTableOfContents}
            />
          </Column>
        </Columns>
      </main>

      <NextStepsWrapper
        category={article.primaryCategory}
        nextSteps={nextSteps}
      />
    </Page>
  )

  return (
    <ContentImpression contentId={article.contentId}>
      <ApplyUrlProvider
        location={location}
        attributionTopic={article.attributionTopic}
      >
        <ActivePageSectionProvider>{page}</ActivePageSectionProvider>
      </ApplyUrlProvider>
    </ContentImpression>
  )
}

Article.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    smbArticle: PropTypes.shape({
      contentId: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      jsonld: PropTypes.object,
      customPath: PropTypes.string,
      displayDate: PropTypes.string.isRequired,
      attributionTopic: PropTypes.string.isRequired,
      assigningEditor: PropTypes.object,
      authors: PropTypes.arrayOf(PropTypes.object).isRequired,
      breadcrumb: PropTypes.shape({}).isRequired,
      showAuthorByline: PropTypes.bool.isRequired,
      showEditorialDisclaimer: PropTypes.bool.isRequired,
      hideFeaturedImage: PropTypes.bool.isRequired,
      featuredMedia: PropTypes.shape({}).isRequired,
      synopsis: PropTypes.any,
      heroBannerDocument: PropTypes.any,
      content: PropTypes.any.isRequired,
      authorBox: PropTypes.any.isRequired,
      primaryCategory: PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }).isRequired,
      methodology: PropTypes.shape({
        content: PropTypes.shape({}),
      }),
      smbTableOfContents: PropTypes.shape({}),
      relatedLinks: PropTypes.shape({}),
      useCustomRelatedLinks: PropTypes.bool,
      customRelatedLinks: PropTypes.shape({}),
      nextSteps: PropTypes.shape({}),
      useCustomNextSteps: PropTypes.bool,
      customNextSteps: PropTypes.shape({}),
      seo: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
}

export default Article

export const query = graphql`
  query ($slug: String!) {
    smbArticle(slug: { eq: $slug }) {
      contentId
      customPath
      title
      link
      jsonld
      synopsis
      heroBannerDocument
      displayDate
      showAuthorByline
      showEditorialDisclaimer
      attributionTopic
      assigningEditor {
        id
        name
        link
      }
      authors {
        id
        name
        link
      }
      breadcrumb {
        slug
        page {
          title
        }
      }
      showEditorialDisclaimer
      content
      faqs {
        question
        answer
        answerSchema
      }
      methodology {
        content
      }
      hideFeaturedImage
      featuredMedia {
        altText
        croppedImages {
          sourceUrl
          width
          height
          size
        }
      }
      authorBox: authors {
        id
        name
        link
        contactEmail
        linkedin
        twitter
        facebook
        description
        avatarUrls {
          size
          url
        }
      }
      primaryCategory {
        name
        link
      }
      nextSteps
      useCustomNextSteps
      customNextSteps
      relatedLinks
      useCustomRelatedLinks
      customRelatedLinks
      seo {
        title
        description
        keyword
        facebook {
          title
          description
        }
        twitter {
          title
          description
        }
      }
    }
  }
`
